@import './colors.css';

@font-face {
    font-family: "Poppins";
    src: url("./Poppins-Regular.ttf");
}

p, h1, h2, h5, b, small{
    cursor: default;
}

a{
    cursor: pointer !important;
}

.App {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;

    top: 0;
    margin: auto 0;

    padding: 0;

    color: var(--text-soft);
}

#AppBackground {
    position: fixed;
    top: 0;
    padding: 0;

    min-height: 100vh;
    z-index: -100;

    background-color: var(--background-secondary);
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.55);
}


#Name {
    overflow: hidden;
    font-weight: 300;
    animation: introName .45s ease-in-out backwards;
}

@keyframes introName {
    from {
        transform: translateY(280%);
        scale: .25;
    }

    to {
        transform: translateY(0);
        scale: 1;

    }
}

#JobTitle {
    font-weight: 200;
    animation: introJob .5s .3s ease-in-out backwards;

}

@keyframes introJob {
    from {
        transform: translateY(-250%);
        scale: .25;

    }

    to {
        transform: translateY(0);
        scale: 1;
    }
}

#NameDivider {
    opacity: 1;
    margin: 0 auto;
    height: 3px;
    border-color: var(--text-muted);
    animation: introGlow .25s .7s backwards;
}

@keyframes introGlow {
    from {
        border-color: var(--text);
        border-width: 3px;
    }

    to {
        border-width: 1px;
        border-color: var(--text-muted);
    }
}

.ProjectLink,
.ProjectLink h5 {
    color: var(--text);
    transition: all .25s;
    font-weight: 400;
    text-decoration: none;
}

.ProjectLink i {
    display: inline-block;
    transform: translateY(3px);
}

.ProjectLink:hover,
.ProjectLink:hover h5 {
    color: var(--font-green) !important;
}

.ProjectBox video {
    object-fit: cover;
    min-height: 155px;
}

.ProjectImage {
    box-sizing: border-box !important;
}

#content {
    width: 60%;
    position: absolute;
    right: 0;

    background-color: var(--background-thrice);
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.55);
}

.DynamicContainer {
    position: fixed;
    min-width: 450px;
    padding: 0;
}

.SectionBox {
    color: var(--text-soft);

    position: relative;
    padding-top: 60px;
    top: -60px;
    margin: 0;
}

.SectionBox:not(.SectionBox:first-of-type){
    margin-top: 25px;
}

.fs-small{
    font-size: 15px !important;
    line-height: 1.33rem;
    overflow-y: auto;
}


.SectionTitle {
    position: relative;
    text-align: center;
    color: var(--text-bright) !important;
    padding: 12px 0;
    margin: 0;
}



.SectionTitle::after {
    content: "";

    position: absolute;
    left:0;
    right: 0;
    bottom: 6px;

    width: 82.5%;
    height: 2px;
    margin: auto;

    background-color: var(--text-soft);
}

.SubSectionBox {
    min-width: 250px;
    max-width: 650px;

    min-height: 200px;

    border-radius: 15px;
    transition: all .25s;

    color: var(--text-soft);

    background-color: var(--background-light);

    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.33);
    transition: all .25s;
}

.SubSectionBox:hover {
    box-shadow: 0px 0px 30px var(--spotlight);
}

.SubSectionBox h5 {
    color: var(--text);
}


.SelectedBox {
    width: 100%;
    background-color: var(--background-lighter) !important;
}

.MutedBox {
    opacity: 0.5;
    filter: brightness(.85)
}

.highlight {
    color: var(--text);
    transition: color .33s;
    font-weight: 600;
    cursor: default;
}

.highlight:hover {
    color: var(--font-green) !important;
}


@media (max-width: 768px) {
    .highlight {
        display: inline;
    }

    .highlighted-text {
        display: inline;
    }
}

#menu {
    position: sticky;
    width: 100%;
    top: 0;
    right: 0;
    margin: 0;
    z-index: 100;
    overflow: hidden !important;
    max-height: 65px;

    padding: 15px 0 ;

    backdrop-filter: blur(6px);
    background-color: var(--background-thrice-transparent);

}

#menu a {
    position: relative;
    --color: var(--text-soft);
    margin: 0px;
    padding: 5px 20px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    color: var(--color);
    text-align: center;
    text-transform: uppercase;
    transition: all .2s;
    font-size: .9rem;

    animation: introNav calc(calc(.2s * var(--index)) + .7s) .6s ease-in-out backwards;
}

@keyframes introNav {
    from {
        transform: translateY(-250%);
    }

    to {
        transform: translateY(0);
    }

}

#menu a::before {
    content: "";
    height: 100%;
    border-left: 2px var(--text-muted);
}

#menu a:hover,
#menu a#selected {
    --color: #eee;
    font-weight: 700;
}

#menu a::after,
#menu a#selected::after {
    content: "";
    width: 0;
    height: 2px;
    border-radius: 2px;
    margin: 5px -20px;
    display: block;
    position: absolute;
    background-color: var(--color);
    transition: width .2s;
}

#menu a:hover::after,
#menu a#selected::after {
    width: 100%;
}

.TaggedText {
    color: #21F5A0;
    background-color: #09463D;
    font-size: 0.8rem;
    user-select: none;
    cursor: pointer;
}

.ExpandingIcon {
    position: relative;
    width: 24px;
    border-radius: 25px;
    margin: 5px 0px;

    color: var(--text-muted);

    overflow: hidden;
    cursor: pointer;
    user-select: none;

    transition: all .25s;
}

.ExpandingIcon i {
    margin: 0 auto;

    padding: 0.35rem 0;
    animation: intro-up .175s calc(calc(var(--index)*.1s) + 1.5s) backwards ease-out;
}

.ExpandingIcon p {
    opacity: 0;
    margin-top: 14px;
    text-align: center;
    width: 50%;
}

.ExpandingIcon:hover {
    margin: auto -24px;
    width: 10rem;
    min-height: 45px;
    background-color: var(--background-lightest);
    color: var(--text);
}

.ExpandingIcon:hover i {
    margin: 0 0 0 24px;
}

.ExpandingIcon:hover p {
    opacity: 1;
    display: inline;
    margin: auto;
    text-align: center;
    width: 100%;
}


@keyframes intro-up {
    from {
        opacity: 0;
        transform: translateY(100%);

    }

    to {
        opacity: 1;
        transform: translateY(0);

    }
}

.ColorTab{
    padding: 0;
    width: 25%;
    min-height: 100% !important;
}

/* Break point for ipad and below */
@media (max-width: 1401px) {
    #content {
        position: relative;
        box-shadow: -1rem -1rem 3rem rgba(0, 0, 0, 0.33), 1rem 1rem 3rem rgba(0, 0, 0, 0.33);

        width: 100%;
        left: 0;
    }

    .DynamicContainer {
        position: relative;
        min-width: 100%;
        width: 100% !important;
        padding: 0.75rem;
        margin-top: 50px;
        inset: 0 !important;
        box-sizing: border-box;
    }

    .SectionBox {
        padding: 0.75rem !important;
        top: 0px;
    }

    #AppBackground {
        position: fixed;
        top: 0;
        padding: 0;
        right: 0;
        min-width: 100vw;

        min-height: 100vh;
        z-index: -100;
    }

    .ExpandingIcon,
    .ExpandingIcon:hover {
        position: relative;
        margin: 10px auto;
        width: 10rem;
        min-height: 45px;
        background-color: var(--background-lightest);
        color: var(--text);
    }


    .ExpandingIcon i,
    .ExpandingIcon:hover i {
        position: absolute;
        margin: auto 4px;
        padding: 8px 12px;
        margin-left: .2rem;
        animation: none;
    }

    .ExpandingIcon p,
    .ExpandingIcon:hover p {
        display: inline;
        opacity: 1;
        margin: auto 0 auto 24px;
        text-align: center;
        width: 100%;
    }
}
/* Phone screen  */

@media (max-width: 769px) {

    .SubSectionBox {
        width: 100vw;
        margin: 0;
        border-radius: 0;
    }

    .SectionBox{
        padding: 25px 0 !important;
        top: 0px;
    }

    .SectionTitle::after {
        width: 95%;
      }

    .ProjectLink,
    .ProjectLink h5 {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
}