:root{
    --background: #171f27;
    --background-secondary: #0F2335;
    --background-thrice: #192936;
    --background-thrice-transparent: #192936cf;
    
    
    --background-light: #192F40;
    --background-lighter: #1d3444;
    --background-lightest:#243949;
    
    --text-bright: #fff;
    --text: #eee;
    --text-soft: #d0d4d4;
    --text-muted: #a8aaaa;

    --font-green: #05DA84;

    --spotlight: #5ED1FF73;
}

.text-white{
    color: var(--text-bright)
}

.text{
    color: var(--text);
}

.text-soft{
    color: var(--text-soft);
}

.bg-cyan{
    background-color: #079faa !important;
}

.bg-blue{
    background-color: #1b88bb !important;
}

.bg-green{
    background-color: #0fb173 !important;
}

.bg-turq{
    background-color: #07aa94 !important;
}

.text-shadow {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    color: #000; 
}

.image-shadow {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5); 
    border-radius: 8px; 
  }
